<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">

    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-select v-model="searchData.searchType"
                       placeholder="请选择关键字类型"
                       style="width: 120px">
              <el-option
                v-for="item in searchKeyType"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input class=""
                      placeholder="搜索关键字"
                      prefix-icon="el-icon-search"
                      v-model="searchData.searchValue"
                      @keyup.enter.native="search"
                      style="width: 260px">
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-select v-model="searchData.status"
                       placeholder="订单状态"
                       style="width: 120px">
              <el-option
                v-for="item in orderStatusArrayList"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="searchData.timeType"
                       placeholder="时间类型"
                       style="width: 120px">
              <el-option
                v-for="item in searchTimeType"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="查询时间">
            <el-date-picker
              v-model="searchData.selectTime"
              type="daterange"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>

          <el-form-item>
            <el-button @click="downloadExcel" icon="el-icon-download">导出</el-button>
          </el-form-item>
        </el-form>
        <div class="order-page-title">
          <div class="flex-4">
            <div class="flex">
              <div class="flex-3">订单商品</div>
              <div class="flex-1">订单类型</div>
              <div class="flex-1">单价/数量</div>
              <div class="flex-1">退款/售后</div>
            </div>
          </div>
          <div class="flex-1 padding-left-10">订单金额</div>
          <div class="flex-2 padding-left-10">收货人信息</div>
          <div class="flex-1 padding-right-30" style="text-align: right">订单状态</div>
        </div>
      </template>

      <template v-slot:table="row">
        <div class="order-page-list">
          <div class="order-item divide-list" v-for="order in tableData">
            <div class="order-item-top">
              <span v-if="[0].includes(order.orderType)">主订单号：{{order.id}}</span>
              <span v-if="[3].includes(order.orderType)">主订单号：{{order.parentOrderId}}</span>
              <span v-if="[3].includes(order.orderType)" class="margin-left-30">子订单号：{{order.id}}</span>
              <span class="margin-left-30">下单时间：{{order.createTime_text}}</span>
              <span class="margin-left-30" v-if="order.payTime_text">支付时间：{{order.payTime_text}}</span>
            </div>
            <div class="order-item-content">
              <div class="flex-4 border-right">
                <div class="order-item-goods flex" v-for="goods in order.orderGoodsList">
                  <div class="flex flex-3">
                    <el-image class="radius-6" style="width: 62px; height: 62px" :src="goods.cover"></el-image>
                    <div class="flex-1 padding-left-10 padding-right-30 flex flex-column">
                      <div class="goods-name flex-1">{{goods.goodsName}}</div>
                      <div class="gray">{{goods.skuName}}</div>
                    </div>
                  </div>
                  <div class="flex flex-1">
                    <div >{{ orderTypeList[order.orderType].label }}</div>
                  </div>
                  <div class="flex-1 flex flex-column">
                    <div class="goods-price flex-1">¥ {{goods.price}}</div>
                    <div class="gray"> x{{goods.goodsNum}}</div>
                  </div>
                  <div class="goods-refund flex-1">
                    <div v-if="isNormalLongId(goods.orderRefundId)">
                      <el-link style="color: red" :underline="false" :href="`/orderRefundDetail?refundId=${goods.orderRefundId}`">{{goodsRefundStatus[goods.refundStatus]}}</el-link>
                    </div>
                    <div v-else>{{goodsRefundStatus[goods.refundStatus]}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 padding-left-10 border-right">
                <div class="padding-10">
                  <div class="f18 b">￥{{order.realAmount}}</div>
                  <div class="f14 gray margin-top-10">运费：￥{{order.expressFee}}</div>
                </div>
              </div>
              <div class="flex-2 padding-left-10 border-right">
                <div class="padding-10">
                  <div class="flex">
                    <div class="">买家</div>
                    <div class="flex-1 margin-left-30 f14">
                      <div class=""><span>{{order.receiveName}}</span> <span class="margin-left-30 f16">{{order.receivePhone}}</span> </div>
                      <div class="margin-top-10">{{order.receiveAddressInfo}}</div>
                    </div>
                  </div>
                  <div class="flex margin-top-30">
                    <div class="">留言</div>
                    <div class="flex-1 margin-left-30 f14 red">{{ order | remark }}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 padding-right-30" style="text-align: right">
                <div class="padding-10">
                  <div class="f18 b">{{orderStatusList[order.status]}}</div>
                  <div class="">
                    <el-button size="medium" type="text" @click="toShowDetail(order.id)">详情</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template slot="after">
        <div class="paging-row">
          <el-pagination
            v-if="totalListNum"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalListNum">
          </el-pagination>
        </div>
      </template>

    </table-height-auto>

    <el-drawer
      ref="drawerViewExpressGoods"
      :close-on-press-escape="false"
      :wrapperClosable="false"
      :destroy-on-close="true"
      :visible.sync="visibleViewExpressGoods"
      title="查看物流单发货信息"
      direction="rtl"
      size="50%">
      <el-table  :data="expressGoodsTable" height="600" style="margin-left:50px;">
        <el-table-column
          prop="goodsId"
          label="商品编码"
          width="180">
        </el-table-column>
        <el-table-column
          label="商品图片"
          width="150">
          <template slot-scope="scope">
            <img class="goods-cover" :src="scope.row.cover" style="width: 100px; height: 100px">
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品名称"
          width="200">
        </el-table-column>
        <el-table-column
          prop="skuName"
          label="规格"
          width="100">
        </el-table-column>
        <el-table-column
          prop="num"
          label="发货数量"
          width="100">
        </el-table-column>
      </el-table>
    </el-drawer>

    <el-drawer
      class="drawer-scroll-y"
      ref="drawerViewExpress"
      @closed="closed"
      :close-on-press-escape="false"
      :wrapperClosable="false"
      :destroy-on-close="true"
      :visible.sync="visibleExpressDrawer"
      title="查看物流信息"
      direction="rtl"
      size="40%">
      <div class="drawer-scroll-y-box" style="padding-top:60px;padding-left:50px;" id="sendOrder">
        <div class="flex-item-form-header">
          <div style="width:80px;">快递信息：</div>
          <div>【{{selectedExpressObj.expressName}}】 &nbsp;&nbsp;{{selectedExpressObj.expressSn}}</div>
        </div>
        <div class="flex-item-form-header">
          <div style="width:80px;">收货信息：</div>
          <div >{{selectedExpressObj.receiveAddressInfo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{selectedExpressObj.receiveName}} &nbsp;&nbsp;&nbsp;&nbsp;{{selectedExpressObj.receivePhone}}</div>
        </div>

        <div class="flex-item-form-header">
          <div style="width:150px;"><b>物流信息</b></div>
          <div>&nbsp;</div>
        </div>
        <div class="banner-item">
          <div >
            <el-table
              :data="orderExpressDetail.data"
              height="300vH">
              <el-table-column
                prop="time"
                width="180">
              </el-table-column>
              <el-table-column
                prop="context"
                width="400">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {orderStatusList,orderStatusArrayList,goodsRefundStatus,goodsRefundStatusArray} from '../../../assets/js/status.js'
  import {isEmpty,disposeSearchData,parseTimeArray,getNextDayTimestamp,isNormalLongId} from '../../../assets/js/utils'
  import {plusDownloadExcelTask} from '../../../store/excel'
  import {mapState} from 'vuex'

  export default {
    name: 'supplierOrderList',
    components: {
    },
    filters: {
        remark(data) {
            if (data.orderType === 4 && data.remark) {
                return JSON.parse(data.remark).remark;
            } else {
                return data.remark;
            }
        }
    },
    data(){
      return {
        loading: false,
        /* 数据表格 */
        orderStatusList,
        orderStatusArrayList,
        goodsRefundStatus,
        goodsRefundStatusArray,
        searchKeyType:[
          {id: 'orderId', name: '订单编号'},
          {id: 'receiveName', name: '收件人姓名'},
          {id: 'receivePhone', name: '收件人电话'},
          {id: 'receiveAddressInfo', name: '收件人地址'},

        ],
        searchTimeType:[
          {id: '', name: '全部'},
          {id: 'createTime', name: '下单时间'},
          {id: 'payTime', name: '支付时间'},
          {id: 'sendTime', name: '发货时间'},
          {id: 'receiveTime', name: '收货时间'},
          {id: 'completeTime', name: '完结时间'},

        ],
        searchData:{
          searchType:'orderId',
          searchValue:'',
          status:0,
          timeType:'',
          businessType:0,
          selectTime:[],
        },
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        tableData: [], // 数据列表
        currentPage: 1, // 当前页码
        totalListNum: 0, // 列表总条量
        pageSize: 5, // 每页显示得条数
        multipleSelection:[],//多选框选中数据
        visibleViewExpressGoods:false,//查看物流发货商品信息弹框
        visibleExpressDrawer:false,//查看物流弹窗
        expressGoodsTable:[],
        orderExpressDetail:{},
        selectedExpressObj:{},

        orderTypeList: [
          { value: 0, label: '普通订单' },
          { value: 1, label: '送礼订单' },
          { value: 2, label: '盲盒订单' },
          { value: 3, label: '收礼订单' },
          { value: 4, label: '农场租赁订单' },
          { value: 5, label: '农场提菜订单' },
          { value: 6, label: '农场服务订单' },
        ],

      }
    },
    created(){
      this.parseQuery();
      this.getList()
    },
    methods:{
      isNormalLongId,
      parseQuery(){
        let params = this.$route.query;
        if(params['status'] === '1'){
          this.searchData.status = 1
        }
      },
      closed() {
        this.$emit('closed', ...arguments)
      },
      /**
       * 拼接搜索参数
       */
      jointSearchData(){
        let data = {
          pageNo:this.currentPage,
          pageSize:this.pageSize,
          businessType:1
        };
        data = Object.assign(data,this.searchData);
        disposeSearchData(data);
        let selectTime = data.selectTime;

        let startTime = 0;
        let endTime = 0;

        if(!isEmpty(selectTime)){
          let times = selectTime.map(res=>new Date(res).getTime())
          startTime = times[0];
          endTime = getNextDayTimestamp(times[1]);
        }

        data.startTime = startTime;
        data.endTime = endTime;

        delete  data.selectTime;
        return data;
      },
      /**
       * 搜索
       */
      search(){
        this.currentPage = 1;
        this.getList()
      },
      /**
       * 查询列表数据
       */
      getList(){
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.supplierOrder.list,{params:data}).then(res=>{
            parseTimeArray(res.data.records,["createTime", "payTime","sendTime", "completeTime"]);
            res.data.records.forEach(itemRes=>{
              parseTimeArray(itemRes.expressList,["createTime", "receiveTime"]);
            });
            this.totalListNum = parseInt(res.data.total);
            this.tableData = res.data.records;
            console.info(res.data.records);
            resolve(res);
          }).catch(res=>{
            this.tableData = [];
            // reject(res);
          }).finally(res=>{
            this.loading = false;
            this.multipleSelection = [];
          })
        })
      },
      /**
       * pageSize 改变时会触发：选择每页显示条数
       */
      handleSizeChange(val,type){
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
      },
      /**
       * currentPage 改变时会触发：选择页数
       */
      handleCurrentChange(val,type){
        this.currentPage = val;
        this.getList();
      },
      /**
       * handleSelectionChange 当选择项发生变化时会触发该事件 table 表格选中
       */
      handleSelectionChange(val){
        this.multipleSelection = val;
      },
      showDetail(row){
        if(!row.hasLoadInfo){
          this.getOrderDetail(row);
        }
        this.$refs['orderTable'].toggleRowExpansion(row);
      },
      getOrderDetail(row){
        this.axios.get(URL.supplierOrder.info,{params:{orderId:row.id}}).then(res=>{
          if(res.code === 0){
            Object.assign(row,res.data);
            row.hasLoadInfo = true;
          }
        })
      },
      toShowDetail(orderId){
        this.$router.push({
          path:'/orderDetail',
          query:{
            orderId:orderId,
          }
        });
      },
      viewExpress(row){
        console.log("row", row);
        this.orderExpressDetail = {};
        let data = {
            expressSn:row.expressSn,
            phone:row.receivePhone,
        };
        this.selectedExpressObj = row;
        this.axios.get(URL.supplierOrder.express,{params:data}).then(res=>{
          let expressDetails = res.data.expressDetails;
          if(!isEmpty(expressDetails)){
            try {
              this.orderExpressDetail = JSON.parse(expressDetails);
            }catch (e) {
              this.orderExpressDetail = {}
            }
          }
          console.log("this.orderExpressDetail",this.orderExpressDetail);
        }).catch(res=>{
        }).finally(res=>{
          this.visibleExpressDrawer = true;
        })
          //window.open('https://www.baidu.com/s?wd='+expressSn);
      },
      viewExpressGoods(row){
        console.log("row", row);
        this.expressGoodsTable = []
        if(!isEmpty(row.orderGoodsJson)){
          try {
            this.expressGoodsTable = JSON.parse(row.orderGoodsJson);
          }catch (e) {
            this.expressGoodsTable = []
          }
        }
        console.log("this.expressGoodsTable", this.expressGoodsTable);
        this.visibleViewExpressGoods = true;
      },
      /**
       * 导出excel
       */
      downloadExcel(){
        let searchData = this.jointSearchData();
        let data = {
          name:'供应商订单报表',
          params:{
            excelType:1000,
            excelParameterJson:JSON.stringify(searchData),
          },
        };
        plusDownloadExcelTask.call(this,data);
      },
    },
    computed:{
      ...mapState({
        showPoint:state=>state.userInfo.showCostAndPurchase,
      }),
    },
  }
</script>

<style lang="less">
  .form-item{
    .el-input{
      max-width: 300px;
    }
  }
</style>
<style lang="less" scoped>
  @import "../../../assets/css/order";
</style>
